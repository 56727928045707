.notification-detail-style {
  width: 100%;
  height: 827px;
  border-left: 1px solid #dcdcdc;

  .notification-header-item {
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
    height: 66px;

    .notification-header-name {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #000000;
    }
  }

  .notification-detail-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 761px;
  }
}
