.apartment-list {
  display: flex;
  flex-direction: column;
  padding: 30px 22px 30px 22px;
  border-left: 1px solid #dcdcdc;
  height: 761px;
  overflow-y: auto;

  .apartment-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .apartment-chekbox {
      margin-right: 15px;
    }

    .apartment-name {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #121715;
      opacity: 0.8;
      word-break: break-word;
    }
  }
}
