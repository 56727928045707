.notification-detail-form {
  width: 516px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .notification-form {
    padding: 30px 22px 30px 22px;

    .notification-detail-form-row {
      display: flex;
      margin-bottom: 26px;

      .notification-image-preview-container {
        display: inline;
        position: relative;
        width: 320px;
        height: 165px;
        .notification-image-preview-img {
          width: 320px;
          height: 165px;
          border-radius: 8px;
        }
        .notification-image-preview-close {
          position: absolute;
          right: 3px;
          top: 3px;
          padding: 1px;
          color: #ffffff;
          cursor: pointer;
          background: #000000;
          opacity: 0.5;
          border-radius: 50%;
        }
      }
    }
  }

  .notification-detail-form-row-action {
    border-top: 1px solid #dcdcdc;
    padding: 15px 22px 15px 22px;
    display: flex;
    justify-content: flex-end;
    .MuiButton-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

.notification-success {
  .MuiAlert-root {
    border-radius: 8px !important;
  }
  .MuiAlert-filledSuccess {
    background-color: #f0fff1 !important;
    padding: 15px !important;
  }
  .MuiAlert-filledSuccess .MuiSvgIcon-root {
    fill: #179f57;
    height: 24px;
    width: 24px;
  }
  .MuiAlert-action {
    align-items: flex-start !important;
  }
  .MuiSvgIcon-fontSizeSmall {
    fill: #617367 !important;
    height: 16px !important;
    width: 16px !important;
  }
  .success-heading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #012910;
  }
  .success-secondary {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #617367;
  }
}
