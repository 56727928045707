.community-list-style {
  width: 320px;
  height: 827px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dcdcdc;

  .community-header-item {
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    border-bottom: 1px solid #dcdcdc;

    .community-header-name {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #121715;
    }
  }

  .community-list-body {
    overflow-y: auto;

    .community-item {
      padding: 20px 25px 20px 25px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #dcdcdc;
      justify-content: space-between;
      cursor: pointer;
      align-items: center;

      .community-name {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #121715;
        display: flex;
        flex: 1;
        word-break: break-word;
      }
    }
  }
}
