.side-nav {
  height: 100%;
  width: 130px;
  background-color: #179f57;
  overflow-x: hidden;
  padding: 30px 0px 50px 0px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  .botiga-logo {
    width: 72px;
    height: 67px;
    margin-bottom: 55px;
  }

  .menu-icon-items {
    width: 90px;
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    text-decoration: none;

    .image-icon {
      width: 32px;
      height: 32px;
    }

    .text-icon {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      text-transform: uppercase;
      color: #ffffff;
    }
  }

  .logout-menu-item {
    position: fixed;
    bottom: 0;
  }

  .active {
    background-color: seagreen;
    border-radius: 10px;
  }
}
