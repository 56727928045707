.login {
  position: relative;
  top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 452px;
  height: 322px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  margin: auto;
  padding: 50px;

  .main-logo-conatiner {
    display: flex;
    justify-content: center;

    .main-logo {
      width: 148.06px;
      height: 40px;
    }
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .MuiSvgIcon-root {
    color: rgba(18, 23, 21, 0.5);
  }
  .MuiButton-containedPrimary {
    height: 50px;
    margin-top: 40px;
  }
  .MuiButton-label {
    text-transform: capitalize;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }
}

.verify-otp {
  position: relative;
  top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 452px;
  height: 418px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  margin: auto;
  padding: 50px;

  .main-logo-conatiner {
    display: flex;
    justify-content: center;

    .main-logo {
      width: 148.06px;
      height: 40px;
    }
  }
  .description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #121715;
  }
  .otpForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .verify-otp-inputs input {
      width: 40px !important;
      height: 50px !important;
      background: rgba(18, 23, 21, 0.05);
      border-radius: 8px;
      border: none;
    }
    .verify-otp-inputs input:focus {
      outline: #179f57 auto 1px;
      outline-color: #179f57;
      outline-style: auto;
      outline-width: 1px;
    }
    .MuiButton-containedPrimary {
      height: 50px;
      margin-top: 40px;
    }
    .MuiButton-label {
      text-transform: capitalize;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
    }
    .otp-seprator {
      width: 14px;
    }
    .resend {
      margin-top: 20px;
    }
    .resendText {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: rgba(18, 23, 21, 0.5);
    }
    .resendbtn {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: rgba(18, 23, 21, 0.5);
      cursor: pointer;
    }
  }
}
