.notification-upload-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 160px;
  border: 1px dashed rgba(18, 23, 21, 0.25);
  box-sizing: border-box;
  border-radius: 8px;

  .add-image-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background: rgba(18, 23, 21, 0.05);
    border-radius: 8px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    margin-bottom: 5px;
    color: #372d21;
    background-color: rgb(239, 239, 239);
  }

  .description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000000;
    opacity: 0.5;
  }
}
